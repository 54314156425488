import { useMediaQuery } from '@/hooks';
import { Link, useParams } from '@tanstack/react-router';

import { useGetProject } from '@/api/projects/queries';

import { SidebarMenuButton } from '../sidebar/sidebar-menu-button';

type Props = {
  type: 'projects' | 'global';
};

export const Heading = ({ type }: Props) => {
  const { projectId } = useParams({ strict: false }) as { projectId?: string };
  const { data: project } = useGetProject(projectId);
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <div className="sticky top-0 z-10 flex h-[48px] items-center border-b bg-background/80 backdrop-blur-lg">
      <div className="flex items-center space-x-3 overflow-hidden px-4 text-sm text-gray-500 dark:text-gray-400">
        {!isDesktop && <SidebarMenuButton type={type} />}
        <Link className="transition-colors hover:text-black dark:hover:text-white" to="/projects">
          Projects
        </Link>
        {type === 'projects' && project && (
          <>
            <span>/</span>
            <Link
              className="truncate text-black hover:underline dark:text-white"
              to="/projects/$projectId/requests"
              params={{ projectId: project.id }}
            >
              {project.name}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
