import PoweredByStripeImage from '@/assets/powered-by-stripe.svg?react';
import { useUser } from '@clerk/clerk-react';
import { Navigate, createFileRoute } from '@tanstack/react-router';
import { CreditCardIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

import { useCreatePortal, useGetSubscription } from '@/api/payments/queries';

export const Route = createFileRoute('/_authenticated/_global/billing')({
  component: BillingComponent,
});

function BillingComponent() {
  const { user, isLoaded } = useUser();
  const { data } = useGetSubscription();
  const mutation = useCreatePortal();

  const navigateToBilling = async () => {
    await mutation.mutateAsync(undefined, {
      onSuccess: (data) => {
        window.location.href = data.url;
      },
    });
  };

  if (isLoaded && user?.publicMetadata?.plan === 'free') {
    return <Navigate to="/pricing" />;
  }

  return (
    <div className="mx-auto flex h-screen w-full max-w-6xl flex-col px-4 py-3 md:px-8">
      <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
        <Card>
          <CardHeader className="flex items-center gap-2">
            <CreditCardIcon className="h-6 w-6" />
            <CardTitle>Billing Information</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-2 text-sm">
            <div className="grid grid-cols-2 gap-2">
              <div className="font-semibold">Plan</div>
              <div className="text-right">Pro</div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="font-semibold">Amount</div>
              <div className="text-right">{data?.amount}</div>
            </div>
          </CardContent>
          <CardFooter className="flex flex-1 flex-col">
            <div>
              <Button onClick={navigateToBilling} size="sm">
                Update billing information
              </Button>
            </div>
            <PoweredByStripeImage className="fill-black dark:fill-white" width={140} />
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
