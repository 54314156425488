import { SignUp } from '@clerk/clerk-react';
import { createFileRoute } from '@tanstack/react-router';

import { Logo } from '@/components/layout/logo';

export const Route = createFileRoute('/sign-up')({
  component: SignUpComponent,
});

function SignUpComponent() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6">
      <Logo />
      <SignUp signInUrl="/sign-in" routing="hash" />
    </div>
  );
}
