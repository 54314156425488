import { getToken } from '@/api/types';

import { http } from '..';
import { CreateProjectDto, Project, Stat, UpdateProjectDto } from './types';

export const fetchAll = async (getToken: getToken): Promise<Project[]> => {
  const token = await getToken();
  return http.url('/projects').auth(`Bearer ${token}`).get().json();
};

export const fetchOne = async (getToken: getToken, id: string): Promise<Project> => {
  const token = await getToken();
  return http.url(`/projects/${id}`).auth(`Bearer ${token}`).get().json();
};

export const update = async (getToken: getToken, id: string, data: UpdateProjectDto) => {
  const token = await getToken();
  return http.url(`/projects/${id}`).auth(`Bearer ${token}`).put(data).json();
};

export const fetchRequestsStats = async (getToken: getToken, id: string): Promise<Stat> => {
  const token = await getToken();
  return http.url(`/projects/${id}/requests/stats`).auth(`Bearer ${token}`).get().json();
};

export const create = async (getToken: getToken, data: CreateProjectDto): Promise<Project> => {
  const token = await getToken();
  return http.url(`/projects`).auth(`Bearer ${token}`).post(data).json();
};

export const deleteProject = async (getToken: getToken, id: string) => {
  const token = await getToken();
  return http.url(`/projects/`).auth(`Bearer ${token}`).delete(id).json();
};

export const fetchProjectsStats = async (getToken: getToken, id: string): Promise<Stat> => {
  const token = await getToken();
  return http.url(`/projects/${id}/stats/summary`).auth(`Bearer ${token}`).get().json();
};
