import { SignOutButton, useAuth, useUser } from '@clerk/clerk-react';
import { Link, useNavigate } from '@tanstack/react-router';
import { ChevronDown, LogOut, User } from 'lucide-react';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { Button } from '../ui/button';

const FALLBACK_AVATARS = ['🐱', '🐶', '🐻', '🐹', '🦊', '🐨', '🐼', '🐰', '🐯', '🦁'];

export const UserMenu = () => {
  const { user } = useUser();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const emailAddress = user?.primaryEmailAddress?.emailAddress;

  const fallbackAvatar = emailAddress
    ? FALLBACK_AVATARS[emailAddress.length % FALLBACK_AVATARS.length]
    : null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="h-13 flex cursor-pointer items-center justify-between gap-3 px-1"
          variant="ghost"
        >
          <Avatar>
            <AvatarImage className="object-cover" src={user?.imageUrl} />
            <AvatarFallback className="text-lg">{fallbackAvatar}</AvatarFallback>
          </Avatar>
          <div className="mr-auto truncate text-start text-sm">{emailAddress}</div>
          <ChevronDown className="text-muted-foreground" size={16} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuItem asChild className="cursor-pointer">
            <Link to="/user-settings" className="flex flex-1 items-center">
              <User className="mr-2 h-4 w-4" />
              <span>Profile</span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer"
          onSelect={() => signOut(() => navigate({ to: '/sign-in' }))}
        >
          <SignOutButton redirectUrl="/sign-in">
            <>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Log out</span>
            </>
          </SignOutButton>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
