import { valibotResolver } from '@hookform/resolvers/valibot';
import { PlusCircle } from 'lucide-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { InferInput as ValibotInput, maxLength, minLength, object, pipe, string } from 'valibot';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const FormSchema = object({
  name: pipe(
    string(),
    minLength(1, 'Please enter Project title'),
    maxLength(50, 'Title cannot exceed 50 symbols'),
  ),
  description: string(),
});

export type FormDataType = ValibotInput<typeof FormSchema>;

export type Props = {
  onSubmit: (data: FormDataType) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const CreateProjectDialog = ({ onSubmit, isOpen, setIsOpen }: Props) => {
  const form = useForm<FormDataType>({
    resolver: valibotResolver(FormSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  useEffect(() => {
    if (!isOpen) form.reset();
  }, [isOpen, form]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="default" className="text-sm font-medium">
          <PlusCircle className="mr-2 text-primary-foreground/70" /> New Project
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col gap-8 p-6">
        <DialogHeader>
          <DialogTitle>Create Project</DialogTitle>
          <DialogDescription>Fill the form to create new project</DialogDescription>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full flex-col space-y-6">
              <div className="space-y-4">
                <FormField
                  disabled={form.formState.isSubmitting}
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Title</FormLabel>
                      <FormControl>
                        <Input placeholder="Project title" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button
                className="self-end"
                type="submit"
                disabled={form.formState.isSubmitting || !form.formState.isDirty}
              >
                Create Project
              </Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateProjectDialog;
