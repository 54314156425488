import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { ClerkProvider } from '@/components/providers/clerk-provider';
import { ThemeProvider } from '@/components/providers/theme-provider';

import App from './App.tsx';
import { Toaster } from './components/ui/toaster.tsx';
import './index.css';

const { VITE_CLERK_PUBLISHABLE_KEY, VITE_ENV, VITE_SENTRY_DSN } = import.meta.env;

console.log('ENVIRONMENT', VITE_ENV, import.meta.env.MODE);

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  environment: VITE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://api.featurebuddy.com'],
    }),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (!VITE_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="featurebuddy-ui-theme">
      <ClerkProvider publishableKey={VITE_CLERK_PUBLISHABLE_KEY}>
        <App />
        <Toaster />
      </ClerkProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
