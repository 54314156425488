import { useMediaQuery } from '@/hooks';

import { SidebarDesktop } from './sidebar-desktop';

type Props = {
  type: 'projects' | 'global';
};

export const Sidebar = ({ type }: Props) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  if (!isDesktop) return null;

  return <SidebarDesktop type={type} />;
};
