import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

type Props = {
  onChange: (value: string) => void;
};

export const PricingSwitch = ({ onChange }: Props) => (
  <Tabs defaultValue="monthly" className="mx-auto w-40" onValueChange={onChange}>
    <TabsList className="px-2 py-6">
      <TabsTrigger value="monthly" className="text-base">
        Monthly
      </TabsTrigger>
      <TabsTrigger value="yearly" className="text-base">
        Yearly
      </TabsTrigger>
    </TabsList>
  </Tabs>
);
