import clsx from 'clsx';
import { Star } from 'lucide-react';

import { Badge } from './ui/badge';

type Props = {
  className?: string;
};

export const ProTierBadge = ({ className }: Props) => (
  <Badge variant="outline" className={clsx('ml-2 select-none px-2 uppercase', className)}>
    <Star className="mr-1 h-2.5 w-2.5 fill-yellow-500 text-yellow-700 dark:fill-yellow-100 dark:text-yellow-300" />
    Pro
  </Badge>
);
