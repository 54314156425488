import Avatar from 'boring-avatars';

type Props = {
  userId?: string;
  size?: number;
};

export const UserAvatar = ({ userId, size = 40 }: Props) => {
  return (
    <Avatar
      size={size}
      name={userId}
      colors={['#382A2A', '#E75A7A', '#FF9D7D', '#E5EBBC', '#8DC4B7']}
    />
  );
};
