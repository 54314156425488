import { Link } from '@tanstack/react-router';
import { ArrowRight, Settings } from 'lucide-react';

import { Card, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

import { Project } from '@/api/projects/types';

type Props = {
  project: Project;
};

export const ProjectItem = ({ project }: Props) => {
  return (
    <Card className="relative flex h-44 w-auto flex-col transition-colors hover:border-slate-300 dark:hover:border-slate-700">
      <Link
        to="/projects/$projectId/requests"
        params={{ projectId: project.id }}
        className="grow p-5"
      >
        <CardHeader className="p-0 pl-1">
          <CardTitle className="line-clamp-2 break-words leading-relaxed">{project.name}</CardTitle>
        </CardHeader>
      </Link>
      <CardFooter className="flex items-end justify-end p-0">
        <Link
          to="/projects/$projectId/settings"
          params={{ projectId: project.id }}
          className="group absolute mb-2 flex items-center justify-end space-x-1 px-4 py-2 text-sm text-slate-500 transition-colors hover:text-slate-800 dark:text-slate-500 dark:hover:text-slate-300"
        >
          <Settings className="transition-transform group-hover:rotate-180" size={16} />
          <p>Settings</p>
          <ArrowRight
            size={16}
            className="mt-0.5 block transition-transform group-hover:translate-x-0.5"
          />
        </Link>
      </CardFooter>
    </Card>
  );
};
