import { Outlet, createFileRoute } from '@tanstack/react-router';

import { Heading } from '@/components/layout/heading';
import { Sidebar } from '@/components/sidebar';

export const Route = createFileRoute('/_authenticated/_global')({
  component: GlobalLayout,
});

function GlobalLayout() {
  return (
    <div className="h-screen min-h-screen w-full">
      <Sidebar type="global" />
      <div className="flex w-screen flex-col lg:pl-[17.5rem]">
        <Heading type="global" />
        <Outlet />
      </div>
    </div>
  );
}
