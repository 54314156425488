import { useAuth } from '@clerk/clerk-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  create,
  deleteProject,
  fetchAll,
  fetchOne,
  fetchProjectsStats,
  fetchRequestsStats,
  update,
} from '.';
import { CreateProjectDto, Project, UpdateProjectDto } from './types';

export const useGetProjects = () => {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['projects', 'list'],
    queryFn: () => fetchAll(getToken),
  });
};

export const useGetProject = (id?: string) => {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['projects', 'details', id],
    queryFn: () => fetchOne(getToken, id as string),
    enabled: Boolean(id),
  });
};

export const useGetProjectRequestsStats = (id: string) => {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['projects', 'stats', id],
    queryFn: () => fetchRequestsStats(getToken, id),
  });
};

export const useUpdateProject = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, data }: { projectId: string; data: UpdateProjectDto }) => {
      return update(getToken, projectId, data);
    },
    onSuccess: (_, { projectId, data }) => {
      queryClient.setQueryData<Project>(['projects', 'details', projectId], (oldData) => {
        if (oldData) {
          return { ...oldData, ...data };
        }
      });
      queryClient.invalidateQueries({ queryKey: ['projects', 'list'] });
      // queryClient.invalidateQueries({ queryKey: ['projects', 'details', projectId] });
    },
  });
};

export const useCreateProject = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateProjectDto) => {
      return create(getToken, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects', 'list'] });
    },
  });
};

export const useDeleteProject = (projectId: string) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => {
      return deleteProject(getToken, id);
    },
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ['projects', 'details', projectId] });
      queryClient.invalidateQueries({ queryKey: ['projects', 'list'] });
    },
  });
};

export const useGetProjectsStats = (id: string) => {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['projects', 'stats', id],
    queryFn: () => fetchProjectsStats(getToken, id),
  });
};
