import { getToken } from '@/api/types';

import { http } from '..';
import {
  CreatePortalResponseDto,
  CreateSubscriptionRequestDto,
  CreateSubscriptionResponseDto,
  GetStripPricesResponseDto,
  GetStripeSubscriptionResponseDto,
} from './types';

export const fetchConfig = async (getToken: getToken): Promise<GetStripPricesResponseDto> => {
  const token = await getToken();
  return http.url('/payments/config').auth(`Bearer ${token}`).get().json();
};

export const fetchSubscription = async (
  getToken: getToken,
): Promise<GetStripeSubscriptionResponseDto> => {
  const token = await getToken();
  return http.url('/payments/subscription').auth(`Bearer ${token}`).get().json();
};

export const createSubscription = async (
  getToken: getToken,
  data: CreateSubscriptionRequestDto,
): Promise<CreateSubscriptionResponseDto> => {
  const token = await getToken();
  return http.url(`/payments/subscription`).auth(`Bearer ${token}`).post(data).json();
};

export const createCustomerPortalSession = async (
  getToken: getToken,
): Promise<CreatePortalResponseDto> => {
  const token = await getToken();
  return http.url('/payments/portal').auth(`Bearer ${token}`).post().json();
};
