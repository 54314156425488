import { UserProfile } from '@clerk/clerk-react';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_global/user-settings')({
  component: UserSettingsComponent,
});

function UserSettingsComponent() {
  return (
    <div id="clerk-user-settings" className="flex items-center justify-center py-10">
      <UserProfile routing="hash" />
    </div>
  );
}
