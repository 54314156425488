import { useAuth } from '@clerk/clerk-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteComment, fetchAll } from '.';
import { Request } from '../requests/types';
import { Comment, DeleteCommentDto } from './types';

export const useGetComments = (
  projectId: string,
  requestId: string,
  { enabled }: { enabled: boolean },
) => {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['project', projectId, 'requests', requestId, 'comments'],
    queryFn: () => fetchAll(projectId, requestId, getToken),
    enabled,
  });
};

export const useDeleteComment = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ requestId, commentId }: DeleteCommentDto) =>
      deleteComment(requestId, commentId, getToken),
    onSuccess: (_, { requestId, projectId, commentId, status }) => {
      queryClient.setQueryData(
        ['project', projectId, 'requests', requestId, 'comments'],
        (oldComments: Comment[]) => oldComments.filter((item) => item.id != commentId),
      );

      queryClient.setQueryData(
        ['requests', 'project', projectId, status],
        (oldRequests: Request[]) =>
          oldRequests.map((item) =>
            item.id == requestId ? { ...item, commentCount: item.commentCount - 1 } : item,
          ),
      );
    },
  });
};
