import { useParams } from '@tanstack/react-router';

import Loader from '@/components/loader';
import { Sheet, SheetContent } from '@/components/ui/sheet';

import { useGetRequest } from '@/api/requests/queries';
import { Request } from '@/api/requests/types';

import { RequestSheetContent } from './request-sheet-content';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  requestId: string;
  onRequestDelete: () => void;
};

export const RequestSheet = ({ isOpen, setIsOpen, onRequestDelete, requestId }: Props) => {
  const { projectId } = useParams({ strict: false }) as { projectId: string };

  const { data: request, isPending: isRequestPending } = useGetRequest(requestId, projectId);

  return (
    <>
      <Sheet open={isOpen} onOpenChange={() => setIsOpen(false)}>
        <SheetContent className="w-full overflow-y-auto px-4 sm:w-[540px] sm:max-w-none sm:px-6">
          {isRequestPending || !request ? (
            <Loader className="mx-auto w-full" />
          ) : (
            <RequestSheetContent request={request as Request} onRequestDelete={onRequestDelete} />
          )}
        </SheetContent>
      </Sheet>
    </>
  );
};
