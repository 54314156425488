import { getToken } from '@/api/types';

import { http } from '..';
import { Comment } from './types';

export const fetchAll = async (
  projectId: string,
  requestId: string,
  getToken: getToken,
): Promise<Comment[]> => {
  const token = await getToken();
  return http
    .auth(`Bearer ${token}`)
    .get(`/projects/${projectId}/requests/${requestId}/comments`)
    .json();
};

export const deleteComment = async (requestId: string, commentId: string, getToken: getToken) => {
  const token = await getToken();
  return http
    .url(`/requests/${requestId}/comments/${commentId}`)
    .auth(`Bearer ${token}`)
    .delete()
    .res();
};
