// TODO: for some reason types for {({ isActive }) =>  are broken in current tanstack router
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Link, useParams } from '@tanstack/react-router';
import { LucideIcon } from 'lucide-react';

type Link = {
  title: string;
  to: string;
  Icon: LucideIcon;
};

type Props = {
  links: Link[];
};

export const Menu = ({ links }: Props) => {
  const { projectId } = useParams({ strict: false }) as { projectId: string };

  return (
    <>
      {links.map(({ title, to, Icon }) => {
        return (
          <Link
            key={to}
            to={to}
            params={{ projectId }}
            className="group relative flex items-center rounded-lg px-2 py-3 transition-colors before:absolute before:-left-4 before:h-full before:w-0.5 before:content-[''] hover:text-black dark:hover:text-white"
            activeProps={{
              className:
                'dark:text-white bg-slate-200/50 hover:bg-slate-200/60 dark:hover:bg-gray-800/60 dark:bg-gray-800/50 before:bg-indigo-400 hover:before:bg-indigo-400',
            }}
            inactiveProps={{
              className:
                'text-slate-700 dark:text-slate-300 hover:before:bg-indigo-400/35 hover:bg-slate-200/30 dark:hover:bg-gray-800/30',
            }}
          >
            {({ isActive }) => {
              return (
                <>
                  <Icon
                    size={20}
                    className={
                      isActive
                        ? 'stroke-indigo-500 transition-colors dark:stroke-indigo-400'
                        : 'text-slate-700 transition-colors group-hover:text-slate-800 dark:stroke-slate-400 group-hover:dark:stroke-slate-200'
                    }
                  />
                  <div className="ml-2">{title}</div>
                </>
              );
            }}
          </Link>
        );
      })}
    </>
  );
};
