import { useIsFreePlan } from '@/hooks/use-user-plan';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { useParams } from '@tanstack/react-router';
import clsx from 'clsx';
import { format, formatDistanceToNow, sub } from 'date-fns';
import { ChevronDown, File, FileKey2, Info, MessageSquareText, Pencil, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InferInput as ValibotInput, maxLength, minLength, object, pipe, string } from 'valibot';

import Loader from '@/components/loader';
import { ProTierBadge } from '@/components/pro-tier-badge';
import { ProTierPreview } from '@/components/pro-tier-preview';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { SheetDescription, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Textarea } from '@/components/ui/textarea';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { toast } from '@/components/ui/use-toast';
import { UserAvatar } from '@/components/user-avatar';

import { useGetComments } from '@/api/comments/queries';
import { useRemoveRequest, useUpdateRequest } from '@/api/requests/queries';
import { Request } from '@/api/requests/types';

import { STATUSES } from '../-utils/config';
import { ChangeStatusDropdown } from './change-status-dropdown';
import { ConfirmDeleteAlert } from './confirm-delete-alert';
import { RequestComment } from './request-comment';
import { RequestVoteCount } from './request-vote-count';

type Props = {
  request: Request;
  onRequestDelete: () => void;
};

const FormSchema = object({
  name: pipe(
    string(),
    minLength(1, 'Please enter Request title'),
    maxLength(50, 'Title cannot exceed 50 symbols'),
  ),
  description: pipe(
    string(),
    minLength(10, 'Please enter Request description'),
    maxLength(500, 'Title cannot exceed 500 symbols'),
  ),
  note: string(),
  publicNote: string(),
});

const commentsMockData = [
  {
    id: '550e8400-e29b-41d4-a716-446655440015',
    userId: '550e8400-e29b-41d4-a716-446655440001',
    text: 'Wonderful! Definitely need this feature!',
    createdAt: sub(new Date(), { minutes: 15 }).toISOString(),
    requestId: '550e8400-e29b-41d4-a716-446655440001',
  },
  {
    id: '550e8400-e29b-41d4-a716-446655440016',
    userId: '550e8400-e29b-41d4-a716-446655440020',
    text: 'This suggestion is fire 🔥',
    createdAt: sub(new Date(), { days: 7 }).toISOString(),
    requestId: '550e8400-e29b-41d4-a716-446655440015',
  },
  {
    id: '550e8400-e29b-41d4-a716-446655440017',
    userId: '550e8400-e29b-41d4-a716-44665544002d',
    text: 'Great idea ❤️',
    createdAt: sub(new Date(), { days: 31 }).toISOString(),
    requestId: '550e8400-e29b-41d4-a716-446655880015',
  },
];

export type FormDataType = ValibotInput<typeof FormSchema>;

export const RequestSheetContent = ({ request, onRequestDelete }: Props) => {
  const { projectId } = useParams({ strict: false }) as { projectId: string };
  const [requestToRemove, setRequestToRemove] = useState<Request | null>(null);
  const updateMutation = useUpdateRequest();
  const removeMutation = useRemoveRequest();
  const [isEditing, setIsEditing] = useState(false);
  const isFreePlan = useIsFreePlan();

  const { data: comments, isFetching: isCommentsFetching } = useGetComments(projectId, request.id, {
    enabled: !isFreePlan,
  });

  const form = useForm<FormDataType>({
    resolver: valibotResolver(FormSchema),
    defaultValues: {
      name: request.name,
      description: request.description,
      note: request.note,
      publicNote: request.publicNote,
    },
  });

  const onSubmit = (data: FormDataType) => {
    return updateMutation.mutateAsync(
      {
        requestId: request.id,
        projectId: request.projectId,
        data: { ...request, ...data },
      },
      {
        onSuccess: () => {
          toast({ title: 'Request updated' });
          setIsEditing(false);
          form.reset({ ...data });
        },
      },
    );
  };

  const handleChangeStatus = (request: Request, status: string) => {
    return updateMutation.mutateAsync(
      {
        requestId: request.id,
        projectId: request.projectId,
        data: {
          name: request.name,
          description: request.description,
          status,
          note: request.note,
          publicNote: request.publicNote,
        },
      },
      { onSuccess: () => toast({ title: 'Request status updated' }) },
    );
  };

  const handleDeleteAction = (confirmed: boolean) => {
    if (!confirmed) {
      return setRequestToRemove(null);
    }

    if (!requestToRemove) {
      return;
    }

    setRequestToRemove(null);
    return removeMutation.mutateAsync(
      { requestId: requestToRemove.id, projectId: requestToRemove.projectId },
      {
        onSuccess: () => {
          toast({ title: 'Request removed' });
          onRequestDelete();
        },
      },
    );
  };

  const handleCancelEditClick = () => {
    setIsEditing(false);
    form.reset();
  };

  const status = STATUSES[request.status];

  return (
    <>
      <SheetHeader className="mb-4 flex flex-row items-center gap-2">
        <RequestVoteCount count={request.voteCount} />
        <div className="flex flex-col gap-2 text-start">
          <SheetTitle>
            <span className="mr-1 font-normal text-muted-foreground">ID:</span>
            <span>{request.id}</span>
          </SheetTitle>
          <SheetDescription className="space-y-1.5">
            <div className="flex items-center space-x-2">
              <UserAvatar userId={request.userId} size={24} />
              <span>{request.userId}</span>
            </div>
            <div>
              Created on {format(request.createdAt, 'LLL dd, y')} (
              {formatDistanceToNow(request.createdAt)} ago)
            </div>
          </SheetDescription>
        </div>
      </SheetHeader>

      {isEditing ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full flex-col space-y-6">
            <div>
              <div className="space-y-4">
                <FormField
                  disabled={form.formState.isSubmitting}
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-muted-foreground">Title</FormLabel>
                      <FormControl>
                        <Input placeholder="Request title" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  disabled={form.formState.isSubmitting}
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-muted-foreground">Description</FormLabel>
                      <FormControl>
                        <Textarea placeholder="Request description" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  disabled={isFreePlan || form.formState.isSubmitting}
                  control={form.control}
                  name="note"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-muted-foreground">Private Note</FormLabel>
                      {isFreePlan && <ProTierBadge />}
                      <FormControl>
                        <Textarea
                          placeholder="Internal request note. Users won't see this"
                          {...field}
                          {...(isFreePlan ? { value: '' } : undefined)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  disabled={isFreePlan || form.formState.isSubmitting}
                  control={form.control}
                  name="publicNote"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-muted-foreground">Public Note</FormLabel>
                      {isFreePlan && <ProTierBadge />}
                      <FormControl>
                        <Textarea
                          placeholder="Public request note. Visible in request details"
                          {...field}
                          {...(isFreePlan ? { value: '' } : undefined)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex items-center justify-end gap-2">
              <Button className="self-end" variant="ghost" onClick={handleCancelEditClick}>
                Cancel
              </Button>
              <Button
                className="self-end"
                type="submit"
                disabled={form.formState.isSubmitting || !form.formState.isDirty}
              >
                Save changes
              </Button>
            </div>
          </form>
        </Form>
      ) : (
        <div className="flex flex-col gap-6">
          <div>
            <Label className="text-sm text-muted-foreground">Title</Label>
            <div>{request.name}</div>
          </div>
          <div>
            <Label className="text-sm text-muted-foreground">Description</Label>
            <div>{request.description}</div>
          </div>
          <div>
            <Label className={'flex items-center text-sm text-muted-foreground'}>
              <FileKey2 className="mr-1" size={16} /> Private Note
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="ml-1 inline-block" size={14} />
                </TooltipTrigger>
                <TooltipContent className="text-muted-foreground">
                  This is an internal note. Users won't see this.
                </TooltipContent>
              </Tooltip>
              {isFreePlan && <ProTierBadge />}
            </Label>
            <div className={clsx((isFreePlan || !request.note) && 'text-sm text-muted-foreground')}>
              {!isFreePlan && request.note ? request.note : 'Private note is not added yet'}
            </div>
          </div>
          <div>
            <Label className={'flex items-center text-sm text-muted-foreground'}>
              <File className="mr-1" size={16} /> Public Note
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="ml-1 inline-block" size={14} />
                </TooltipTrigger>
                <TooltipContent className="text-muted-foreground">
                  This note is visible for all users in request details.
                </TooltipContent>
              </Tooltip>
              {isFreePlan && <ProTierBadge />}
            </Label>
            <div
              className={clsx(
                (isFreePlan || !request.publicNote) && 'text-sm text-muted-foreground',
              )}
            >
              {!isFreePlan && request.publicNote
                ? request.publicNote
                : 'Public note is not added yet'}
            </div>
          </div>
          <div className="flex h-10 items-center gap-2">
            <ChangeStatusDropdown
              currentStatus={request.status}
              isWithRemoveOption={false}
              onSelect={(status) => handleChangeStatus(request, status)}
              onRemove={() => setRequestToRemove(request)}
              popperContentProps={{ align: 'start' }}
            >
              <Button className="mr-auto h-9 min-w-9 p-2" variant="secondary">
                {status && (
                  <div className="flex items-center gap-1">
                    <status.Icon
                      className={`${status.iconClassName} mr-1 flex-shrink-0`}
                      size={16}
                    />
                    {status.label}
                    <ChevronDown className="text-muted-foreground" size={18} />
                  </div>
                )}
              </Button>
            </ChangeStatusDropdown>
            <Button variant="destructive" onClick={() => setRequestToRemove(request)}>
              <Trash2 size={16} />
            </Button>
            <Separator className="mx-1 h-full" orientation="vertical" />
            <Button
              className="space-x-2"
              variant="outline"
              onClick={() => setIsEditing((value) => !value)}
            >
              <span>Edit</span>
              <Pencil className="text-muted-foreground" size={14} />
            </Button>
          </div>

          <Separator />

          {isCommentsFetching ? (
            <Loader className="mx-auto my-2 size-8" />
          ) : (
            <div>
              <div className="mb-4 inline-flex items-center text-muted-foreground">
                <MessageSquareText className="mr-1.5" size={18} />
                Comments {isFreePlan ? '' : `(${comments?.length})`}{' '}
              </div>
              <div className="group relative flex flex-col gap-6">
                {(comments || commentsMockData)?.map((comment) => (
                  <RequestComment
                    key={comment.id}
                    comment={comment}
                    isAuthor={Boolean(
                      comment.userId && request.userId && comment.userId === request.userId,
                    )}
                  />
                ))}
                {isFreePlan && (
                  <ProTierPreview className="-mx-6 -my-4 !h-[calc(100%+32px)] !w-[calc(100%+32px)] sm:-mx-4" />
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <ConfirmDeleteAlert isOpen={!!requestToRemove} onAction={handleDeleteAction} />
    </>
  );
};
