import { QueryClient } from '@tanstack/react-query';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import React, { Suspense } from 'react';

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : React.lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    );

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  auth: { isSignedIn: boolean; getToken: () => Promise<string | null> };
}>()({
  component: () => (
    <>
      <Suspense fallback={null}>
        <Outlet />
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
});
