import { Outlet, createFileRoute } from '@tanstack/react-router';

import { Heading } from '@/components/layout/heading';
import { Sidebar } from '@/components/sidebar';

export const Route = createFileRoute('/_authenticated/_projects')({
  component: ProjectsLayout,
});

function ProjectsLayout() {
  return (
    <div className="h-screen min-h-screen w-full">
      <Sidebar type="projects" />
      <div className="flex flex-col lg:pl-[17.5rem]">
        <Heading type="projects" />
        <Outlet />
      </div>
    </div>
  );
}
