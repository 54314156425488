import { useNavigate, useParams } from '@tanstack/react-router';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { useGetProjects } from '@/api/projects/queries';

export const ProjectsSelect = () => {
  const { projectId } = useParams({ strict: false }) as { projectId: string };
  const navigate = useNavigate();
  const { data } = useGetProjects();

  const handleProjectChange = (projectId: string) => {
    navigate({ to: '/projects/$projectId/requests', params: { projectId } });
  };

  return (
    <Select value={projectId} onValueChange={handleProjectChange}>
      <SelectTrigger>
        <SelectValue placeholder="Project" />
      </SelectTrigger>
      <SelectContent>
        {data?.map((project) => {
          return (
            <SelectItem key={project.id} value={project.id}>
              {project.name}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};
