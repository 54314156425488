import clsx from 'clsx';
import { Pointer } from 'lucide-react';
import React from 'react';

import { ProTierBadge } from './pro-tier-badge';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const ProTierPreview = ({ children, className }: Props) => (
  <div
    className={clsx(
      'pointer-events-none absolute left-0 top-0 flex h-full w-full items-center justify-center bg-background/50 backdrop-blur-md transition-all duration-500 group-hover:bg-background/0 group-hover:backdrop-blur-0',
      className,
    )}
  >
    <div className="mb-4 flex flex-col items-center space-y-2 text-center text-sm text-muted-foreground transition-all duration-300 group-hover:opacity-0">
      {children ? (
        children
      ) : (
        <>
          <div className="flex items-center space-x-1">
            <ProTierBadge /> <Pointer size={20} />
          </div>
          <p>Hover over for preview with mock data</p>
        </>
      )}
    </div>
  </div>
);
