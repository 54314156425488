import { useRouterState } from '@tanstack/react-router';
import { Menu } from 'lucide-react';
import { useEffect, useState } from 'react';

import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';

import { Button } from '../ui/button';
import { SidebarDesktop } from './sidebar-desktop';

type Props = {
  type: 'projects' | 'global';
};

export function SidebarMenuButton({ type }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const state = useRouterState();

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [state.location.pathname, setIsOpen]);

  return (
    <Sheet open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <SheetTrigger>
        <Button className="h-9 w-9 p-0" variant="outline">
          <Menu onClick={() => setIsOpen(true)} size={18} />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[280px] p-0 sm:max-w-none" side="left" isWithCloseButton={false}>
        <SidebarDesktop type={type} />
      </SheetContent>
    </Sheet>
  );
}
