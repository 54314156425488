import imgUrl from '@/assets/logo.svg';
import { Link } from '@tanstack/react-router';

export const Logo = () => (
  <Link className="mx-auto flex items-center	justify-center gap-3 px-2" to="/">
    <img className="h-[40px] rounded-lg bg-white" src={imgUrl} />
    <div className="flex flex-col uppercase leading-5">
      <span className="font-normal">Feature</span>
      <span className="font-bold tracking-[3px]">Buddy</span>
    </div>
  </Link>
);
