import { ChevronUp } from 'lucide-react';

type Props = {
  count: number;
};

export const RequestVoteCount = ({ count }: Props) => {
  return (
    <div className="flex h-full w-[36px] flex-col items-center justify-center">
      <ChevronUp className="text-lime-600 drop-shadow-[0_0_2px_rgba(163,230,53,1)] dark:text-lime-400" />
      {count}
    </div>
  );
};
