import { Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_board')({
  component: BoardLayout,
});

function BoardLayout() {
  return (
    <div className="h-screen min-h-screen w-full">
      <div className="flex w-screen flex-col lg:pl-[17.5rem]">
        <Outlet />
      </div>
    </div>
  );
}
