import { useAuth } from '@clerk/clerk-react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Router, RouterProvider } from '@tanstack/react-router';
import { RowData } from '@tanstack/react-table';

import { toast } from './components/ui/use-toast';
import { routeTree } from './routeTree.gen';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
  queryCache: new QueryCache({
    onError: () => {
      toast({
        description: `Something went wrong. Please try again later`,
        variant: 'destructive',
      });
    },
  }),
});

const router = new Router({
  routeTree,
  // defaultPreload: 'intent',
  // defaultPreloadStaleTime: 0,
  context: { queryClient, auth: undefined! },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    className: string;
  }
}

const App = () => {
  const { isSignedIn, getToken, isLoaded } = useAuth();

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} context={{ auth: { isSignedIn, getToken }, queryClient }} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default App;
