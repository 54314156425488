import { useAuth } from '@clerk/clerk-react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { createCustomerPortalSession, createSubscription, fetchConfig, fetchSubscription } from '.';
import { CreateSubscriptionRequestDto } from './types';

export const useGetConfig = () => {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['payments', 'config'],
    queryFn: () => fetchConfig(getToken),
  });
};

export const useGetSubscription = () => {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['payments', 'subscription'],
    queryFn: () => fetchSubscription(getToken),
  });
};

export const useCreateSubscription = () => {
  const { getToken } = useAuth();
  return useMutation({
    mutationFn: ({ priceId }: CreateSubscriptionRequestDto) => {
      return createSubscription(getToken, { priceId });
    },
  });
};

export const useCreatePortal = () => {
  const { getToken } = useAuth();
  return useMutation({
    mutationFn: () => createCustomerPortalSession(getToken),
  });
};
