import { ClerkProviderProps, ClerkProvider as Provider } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';

import { useTheme } from './theme-provider';

export function ClerkProvider({ children, ...props }: ClerkProviderProps) {
  const { isDark } = useTheme();

  return (
    <Provider
      {...props}
      appearance={{
        baseTheme: isDark ? dark : undefined,
        elements: {
          card: 'bg-background shadow-none rounded-lg border border-slate-200 dark:border-slate-800',
          navbar: 'border-slate-200 dark:border-slate-800',
          formFieldInput: 'bg-background',
          formButtonPrimary:
            'bg-gradient-to-r from-purple-500 to-indigo-500 transition-colors hover:from-purple-600 hover:to-indigo-600 dark:hover:from-purple-400 dark:hover:to-indigo-400',
        },
        variables: {
          colorPrimary: '#6366f1', // indigo-500
        },
      }}
    >
      {children}
    </Provider>
  );
}
