import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_board/board/$boardId')({
  component: BoardComponent,
});

function BoardComponent() {
  const { boardId } = Route.useParams();

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6">
      Public board by id {boardId}
    </div>
  );
}
