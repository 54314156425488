import { SignIn } from '@clerk/clerk-react';
import { createFileRoute } from '@tanstack/react-router';

import { Logo } from '@/components/layout/logo';

export const Route = createFileRoute('/sign-in')({
  component: SignInComponent,
});

function SignInComponent() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6">
      <Logo />
      <SignIn path="/sign-in" signUpUrl="/sign-up" />
    </div>
  );
}
