import { cleanObject } from '@/utils';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Search, XCircle } from 'lucide-react';
import { useState } from 'react';
import { object, optional, parse, string } from 'valibot';

import Loader from '@/components/loader';
import { Input } from '@/components/ui/input';
import { TooltipProvider } from '@/components/ui/tooltip';

import { useGetRequests } from '@/api/requests/queries';

import { Categories } from './-components/categories';
import { CreateRequestDialog } from './-components/create-request-dialog';
import { RequestSheet } from './-components/request-sheet';
import { RequestsList } from './-components/requests-list';
import { ALL_STATUSES_OPTION, STATUSES } from './-utils/config';

const queryParamsSchema = object({ status: optional(string()), query: optional(string()) });

const VALID_STATUSES = [ALL_STATUSES_OPTION, ...Object.values(STATUSES)].map(
  ({ status }) => status,
);

export const Route = createFileRoute('/_authenticated/_projects/projects/$projectId/requests/')({
  component: RequestsComponent,
  validateSearch: (search: Record<string, unknown>) =>
    parse(queryParamsSchema, {
      ...search,
      status: VALID_STATUSES.includes(search.status as string | undefined)
        ? search.status
        : ALL_STATUSES_OPTION.status,
    }),
});

function RequestsComponent() {
  const navigate = useNavigate({ from: Route.fullPath });
  const { projectId } = Route.useParams();
  const search = Route.useSearch();
  const { data, isPending } = useGetRequests(projectId, cleanObject({ status: search.status }));

  const [isRequestDialogOpened, setIsRequestDialogOpened] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    navigate({ search: (prev) => ({ ...prev, query: event.target.value }), params: true });
  };

  const handleSearchReset = () => {
    navigate({ search: (prev) => ({ ...prev, query: '' }), params: true });
  };

  const handleRequestSheetOpen = (requestId: string) => {
    setIsRequestDialogOpened(true);
    setSelectedRequestId(requestId);
  };

  const handleRequestSheetClose = () => {
    setIsRequestDialogOpened(false);
    setSelectedRequestId('');
  };

  // TODO: add debounce for actual search
  const dataToDisplay = (data || []).filter((request) =>
    request.name.toLowerCase().includes(search.query ?? ''),
  );

  return (
    <TooltipProvider delayDuration={100}>
      <div className="flex flex-1 flex-col items-center px-4 pb-12 pt-3 md:px-8">
        <div className="flex w-full flex-col">
          <div className="mt-6 flex items-center justify-between">
            <h1 className="text-3xl">Feature Requests</h1>
            <CreateRequestDialog />
          </div>
          <div className="mt-6 flex flex-1 flex-col gap-6 sm:flex-row">
            <div className="w-full sm:w-[210px]">
              <Categories />
            </div>
            <div className="flex flex-1 flex-col space-y-4">
              <div className="relative flex items-center">
                <Search className="pointer-events-none absolute left-3 text-slate-500" size={18} />
                <Input
                  className="px-10"
                  placeholder="Search"
                  value={search.query}
                  onChange={handleSearchChange}
                />
                {Boolean(search.query) && (
                  <XCircle
                    className="absolute right-1 p-1.5 text-slate-500 transition-colors  hover:cursor-pointer hover:text-slate-600 dark:hover:text-slate-400"
                    onClick={handleSearchReset}
                    size={36}
                  />
                )}
              </div>
              {isPending ? (
                <div>
                  <Loader className="mx-auto" />
                </div>
              ) : dataToDisplay?.length ? (
                <RequestsList data={dataToDisplay} onItemClick={handleRequestSheetOpen} />
              ) : (
                <div className="flex h-[280px] items-center justify-center">
                  <span className="text-muted-foreground">
                    {search.query
                      ? `Nothing was found for the query "${search.query}"`
                      : 'No requests'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isRequestDialogOpened && (
        <RequestSheet
          isOpen={isRequestDialogOpened}
          setIsOpen={setIsRequestDialogOpened}
          requestId={selectedRequestId}
          onRequestDelete={handleRequestSheetClose}
        />
      )}
    </TooltipProvider>
  );
}
