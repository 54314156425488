import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

import Loader from '@/components/loader';

import { useCreateSubscription, useGetConfig } from '@/api/payments/queries';

import { PricingCard } from './-components/pricing-card';
import { PricingSwitch } from './-components/pricing-switch';

export const Route = createFileRoute('/_authenticated/_global/pricing/')({
  component: PaymentsComponent,
});

const PLANS = {
  basic: {
    title: 'Launch',
    monthlyPrice: 0,
    yearlyPrice: 0,
    description: 'Essential features you need to get started',
    features: ['1 Project', 'Feature requests', 'Feature moderation'],
    actionLabel: 'Current',
  },
  pro: {
    title: 'Scaler',
    monthlyPrice: 15,
    yearlyPrice: 150,
    description: 'Unlock the full potential for your business',
    features: [
      '3 Projects',
      'Feature requests',
      'Extended fearure moderation',
      'Feature comments',
      'Notifications',
      'User segmentation',
      'Priority support',
      'No watermarks',
    ],
    actionLabel: 'Upgrade',
    popular: true,
  },
  custom: {
    title: 'Enterprise',
    description: 'Dedicated support and infrastructure to fit your needs',
    features: ['All from previous plans', 'Priority-driven developement'],
    actionLabel: 'Contact Us',
    exclusive: true,
  },
};

function PaymentsComponent() {
  const { data, isPending } = useGetConfig();
  const mutation = useCreateSubscription();
  const [isYearly, setIsYearly] = useState(false);

  const togglePricingPeriod = (value: string) => {
    setIsYearly(value === 'yearly' ? true : false);
  };

  const handleBuyPro = async () => {
    const lookupKey = isYearly ? 'premium_yearly' : 'premium_monthly';
    const price = data?.find(({ lookup_key }) => lookup_key === lookupKey);

    if (!price) {
      throw new Error('No price');
    }

    await mutation.mutateAsync(
      { priceId: price?.id },
      {
        onSuccess: (data) => {
          window.location.href = data.url;
        },
      },
    );
  };

  const handleContactUs = () => {
    window.location.href = 'mailto:hey@featurebuddy.com';
  };

  if (isPending) {
    return (
      <div className="flex items-center justify-center md:h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center py-8 md:h-screen">
      <div>
        <PricingSwitch onChange={togglePricingPeriod} />
        <section className="mt-8 flex flex-col justify-center gap-8 sm:flex-row sm:flex-wrap">
          <PricingCard
            disabled={mutation.isPending}
            isYearly={isYearly}
            {...PLANS.basic}
            type="basic"
            onSelect={() => {}}
          />
          <PricingCard
            disabled={mutation.isPending}
            isYearly={isYearly}
            {...PLANS.pro}
            type="pro"
            onSelect={handleBuyPro}
          />
          <PricingCard
            disabled={mutation.isPending}
            isYearly={isYearly}
            {...PLANS.custom}
            type="custom"
            onSelect={handleContactUs}
          />
        </section>
      </div>
    </div>
  );
}
