import { getToken } from '@/api/types';

import { http } from '..';
import { CreateRequestDto, GetAllRequestsResponseDto, Request, UpdateRequestDto } from './types';

export const fetchAll = async (
  id: string,
  query: Record<string, string>,
  getToken: () => Promise<string | null>,
): Promise<GetAllRequestsResponseDto> => {
  const token = await getToken();
  return http.auth(`Bearer ${token}`).query(query).get(`/projects/${id}/requests`).json();
};

export const fetchOne = async (
  getToken: getToken,
  id: string,
  projectId: string,
): Promise<Request> => {
  const token = await getToken();
  return http.url(`/projects/${projectId}/requests/${id}`).auth(`Bearer ${token}`).get().json();
};

export const create = async (getToken: getToken, projectId: string, data: CreateRequestDto) => {
  const token = await getToken();
  return http.url(`/projects/${projectId}/requests`).auth(`Bearer ${token}`).post(data).res();
};

export const update = async (getToken: getToken, id: string, data: UpdateRequestDto) => {
  const token = await getToken();
  return http.url(`/requests/${id}`).auth(`Bearer ${token}`).put(data).res();
};

export const remove = async (getToken: getToken, id: string) => {
  const token = await getToken();
  return http.url(`/requests/${id}`).auth(`Bearer ${token}`).delete().res();
};
