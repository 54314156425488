import { useAuth } from '@clerk/clerk-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { create, fetchAll, fetchOne, remove, update } from '.';
import { CreateRequestDto, Request, UpdateRequestDto } from './types';

type CreateRequestQueryDto = {
  projectId: string;
  data: CreateRequestDto;
};

type UpdateRequestQueryDto = {
  projectId: string;
  requestId: string;
  data: UpdateRequestDto;
};

type RemoveRequestQueryDto = {
  requestId: string;
  projectId: string;
};

export const useGetRequests = (id: string, query: Record<string, string>) => {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['requests', 'project', id, query],
    queryFn: () => fetchAll(id, query, getToken),
  });
};

export const useGetRequest = (id: string, projectId: string) => {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['projects', projectId, 'requests', id],
    queryFn: () => fetchOne(getToken, id, projectId),
  });
};

export const useCreateRequest = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ data, projectId }: CreateRequestQueryDto) => {
      return create(getToken, projectId, data);
    },
    onSuccess: (_, { projectId }) => {
      queryClient.invalidateQueries({ queryKey: ['requests', 'project', projectId] });
      queryClient.invalidateQueries({ queryKey: ['projects', 'stats', projectId] });
    },
  });
};

export const useUpdateRequest = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ requestId, data }: UpdateRequestQueryDto) => {
      return update(getToken, requestId, data);
    },
    onSuccess: (_, { requestId, projectId, data }) => {
      queryClient.setQueryData<Request>(
        ['projects', projectId, 'requests', requestId],
        (oldData) => {
          if (oldData) {
            return { ...oldData, ...data };
          }
        },
      );
      queryClient.invalidateQueries({ queryKey: ['requests', 'project', projectId] });
      queryClient.invalidateQueries({ queryKey: ['projects', 'stats', projectId] });
    },
  });
};

export const useRemoveRequest = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ requestId }: RemoveRequestQueryDto) => remove(getToken, requestId),
    onSuccess: (_, { projectId }) => {
      queryClient.invalidateQueries({ queryKey: ['requests', 'project', projectId] });
      queryClient.invalidateQueries({ queryKey: ['projects', 'stats', projectId] });
    },
  });
};
